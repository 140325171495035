/**********
 * JS
 **********/

// jQuery
global.$ = global.jQuery = require('jquery');

// Modernizer
require('./vendor/modernizr.min');

// Cookie Popup
require('jquery-eu-cookie-law-popup/js/jquery-eu-cookie-law-popup');
